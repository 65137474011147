<template>
  <v-autocomplete
      label="Status da Aprovação do Serasa"
      placeholder="Selecione o status"
      :items="options_aprovacao"
      item-text="text"
      item-value="id"
      outlined
      dense
      v-model="aprovacao_selected"
      hide-details
      clearable
      prepend-inner-icon="mdi-list-status"
      v-on:change="(event) => this.$emit('aprovacaoSelecionado', aprovacao_selected)"
  />
</template>

<script>
export default {
  name: "Autocompleteaprovacao",
  props: {
    id: Boolean,
  },
  data: () => ({
        aprovacao_selected: null,
        options_aprovacao: [
          {id: true, text: "Aprovado"},
          {id: false, text: "Reprovado"},
        ],
      }
  ),
  async created() {
    if (this.id) {
      this.aprovacao_selected = this.id
    }
  },
  watch: {
    id: function (val) {
      this.aprovacao_selected = val;
    }
  },
}
</script>