<template>
  <v-autocomplete
      label="UF do Imóvel"
      placeholder="UF do Imóvel"
      :items="options_ufs"
      item-text="label"
      item-value="id"
      outlined
      dense
      v-model="uf_selected"
      hide-details
      clearable
      prepend-inner-icon="mdi-map"
      v-on:change="(event) => this.$emit('ufSelecionado', uf_selected)"
  />
</template>

<script>
import helpers from "@/assets/js/helpers";

export default {
  name: "AutocompleteUf",
  props: {
    uf: String,
  },
  data: () => ({
    options_ufs: [],
    uf_selected: null,
  }),
  watch: {
    uf: function (val) {
      this.uf_selected = val;
    },
  },
  async created() {
    this.options_ufs = helpers.obterUfs();
    if (this.uf) {
      this.uf_selected = this.uf
    }
  }
};
</script>