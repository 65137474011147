<template>
  <v-dialog
      v-model="aberto"
      width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Filtrar Clientes</div>
          <v-btn icon dark @click="fechar" color="orange">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <div class="loading text-center" v-if="loading_dados">
          <v-progress-circular
              size="50"
              color="orange"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-row v-else>
          <v-col cols="12" md="6">
            <v-text-field
                label="Nome"
                placeholder="Nome do cliente"
                outlined
                dense
                hide-details
                v-model="form.nome"
                clearable
                prepend-inner-icon="mdi-face-man"
            />
          </v-col>

          <v-col cols="12" md="6">
            <AutocompleteStatusCliente
                :id="form.statusId"
                v-on:statusSelecionado="statusClienteRetornado"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    label="Data do cadastro"
                    placeholder="Data do Cadastro"
                    outlined
                    dense
                    hide-details
                    :value="form.criadoEm | formataData"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    prepend-inner-icon="mdi-calendar"
                />
              </template>
              <v-date-picker
                  range
                  v-model="form.criadoEm"
              />
            </v-menu>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                label="CPF"
                placeholder="CPF do cliente"
                outlined
                dense
                v-model="form.cpf"
                hide-details
                clearable
                prepend-inner-icon="mdi-card-account-details-outline"
                v-mask="'###.###.###-##'"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                label="RG"
                placeholder="RG do cliente"
                outlined
                dense
                v-model.trim="form.rg"
                hide-details
                clearable
                prepend-inner-icon="mdi-card-account-details"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                label="Cidade"
                placeholder="Cidade do imóvel"
                outlined
                dense
                v-model.trim="form.cidade"
                hide-details
                clearable
                prepend-inner-icon="mdi-map-marker"
            />
          </v-col>

          <v-col cols="12" md="6">
            <AutocompleteUf :uf="form.uf" v-on:ufSelecionado="ufRetornado"/>
          </v-col>

          <v-col cols="12" md="6" v-if="permissaoMaster">
            <AutocompleteEmpresas
                :id="form.empresa"
                v-on:empresaSelecionada="empresaRetornada"
            />
          </v-col>

          <v-col cols="12" md="6">
            <AutocompleteAprovacao
                :id="form.aprovacaoSerasa"
                v-on:aprovacaoSelecionado="aprovacaoRetornado"
            />
          </v-col>
          <v-col cols="12" md="6">
            <AutocompleteStatusVenda
                :id="form.statusVenda"
                v-on:statusSelecionado="statusVendaRetornado"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
            :disabled="loading_dados"
            color="orange darken-3"
            text
            @click="fechar">Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import AutocompleteEmpresas from "@/components/autocomplete/Empresas"
import AutocompleteUf from "@/components/autocomplete/Uf"
import AutocompleteAprovacao from "@/components/autocomplete/AprovacaoSerasa"
import AutocompleteStatusReanalise from "@/components/autocomplete/StatusReanalise";
import AutocompleteStatusCliente from "@/components/autocomplete/StatusCliente";
import AutocompleteStatusVenda from "@/components/autocomplete/StatusVenda";

export default {
  name: "DialogoFiltro",
  mixins: [mixinGeral],
  props: {
    aberto: Boolean
  },
  components: {
    AutocompleteStatusVenda,
    AutocompleteStatusCliente,
    AutocompleteStatusReanalise,
    AutocompleteEmpresas,
    AutocompleteUf,
    AutocompleteAprovacao
  },
  data() {
    return {
      options_empresas: [],
      loading_dados: false,
      form: {
        nome: null,
        cpf: null,
        rg: null,
        cidade: null,
        uf: null,
        aprovacaoSerasa: null,
        empresa: null,
        criadoEm: null,
        statusId: null,
        statusVenda: null,
      }
    };
  },
  created() {
    this.$emit('filtrar', this.form)
  },

  watch: {
    aberto: function (val) {
      if (val) {
        this.loading_dados = false
      } else {
        this.loading_dados = true
      }
    }
  },
  methods: {
    empresaRetornada(value) {
      this.form.empresa = value
    },
    ufRetornado(value) {
      this.form.uf = value
    },
    aprovacaoRetornado(value) {
      this.form.aprovacaoSerasa = value
    },
    statusClienteRetornado(value) {
      this.form.statusId = value
    },
    statusVendaRetornado(value) {
      this.form.statusVenda = value
    },
    fechar() {
      this.$emit('fechar', false);
    }
  },

};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>
