<template>
  <v-dialog v-model="aberto" max-width="900" persistent :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="fechar">
    <v-card class="card-borda">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Consulta à Serasa</div>
          <v-btn icon dark @click="fechar()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5" v-if="!!dados">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                :value="!dados.erro ? 'Realizada com sucesso' : 'Falha na consulta'"
                label="Status da Consulta"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.situacao_status ? 'Sim' : 'Não'"
                label="Situação Regular"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dataSituacao || '-'"
                label="Data da Situação"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="!!dados.pessoa_encontrada ? 'Sim' : 'Não'"
                label="Cliente Encontrado"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="score || '-'"
                label="Pontuação do Score"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="capacidadePagamento || '-'"
                label="Capacidade de Pagamento"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.possui_restricao ? 'Sim' : 'Não'"
                label="Possui Restrições"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.pefin ? 'Sim' : 'Não'"
                label="Possui Pendências Financeiras"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.refin ? 'Sim' : 'Não'"
                label="Possui Restrições Financeiras"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.cheques_sem_fundos ? 'Sim' : 'Não'"
                label="Possui Cheques sem Fundos"
                outlined
                hide-details

                dense

                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.protestos ? 'Sim' : 'Não'"
                label="Possui Protestos"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.acoes_judiciais ? 'Sim' : 'Não'"
                label="Possui Ações Judiciais"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.part_falencias ? 'Sim' : 'Não'"
                label="Possui Participações em Falências"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                :value="dados.falencias_concordatas ? 'Sim' : 'Não'"
                label="Possui Falências ou Concordatas"
                outlined
                hide-details
                dense
                readonly
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="orange"
            @click="fechar">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import helpers from "@/assets/js/helpers";

export default {
  name: "DialogConsultaSerasa",
  mixins: [],
  components: {},
  data() {
    return {
      aberto: false,
      dados: null,
    };
  },
  computed: {
    backgroundInput() {
      return "grey lighten-3";
    },
    capacidadePagamento() {
      if (!this.dados) return "Capacidade não informada";
      if (!this.dados.capacidade_pagamento) return "Capacidade não informada";
      const capacidade = this.dados.capacidade_pagamento;
      const formatado = helpers.numericoParaMonetarioBrasil(capacidade);
      return `R$ ${formatado}`;
    },
    dataSituacao() {
      if (!this.dados) return;
      const data = this.dados.situacao_data;
      return helpers.formatarDataBrasil(data);
    },
    score() {
      if (!this.dados) return "Score não informado";
      if (!this.dados.score) return "Score não informado";
      return this.dados.score;
    },
  },
  methods: {
    exibir() {
      this.aberto = true;
    },
    fechar() {
      this.aberto = false;
      this.dados = null;
    },
  },
  watch: {},
  created() {
  },
  filters: {
    formatoData(valor) {
      if (!valor) return "";
      let data = new Date(valor);
      let dia = data.getDate();
      let mes = data.getMonth() + 1;
      let ano = data.getFullYear();

      return (
          String("00" + dia).slice(-2) +
          "/" +
          String("00" + mes).slice(-2) +
          "/" +
          ano +
          " " +
          valor.substring(11)
      );
    },
  },
};
</script>

<style scoped>
.card-borda {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>
