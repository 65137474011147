<template>
  <v-dialog v-model="aberto" width="400" @keydown.esc="fechar">
    <v-card>
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Enviar Documento</div>
          <v-btn icon dark @click="fechar()" color="var(--cor-primaria-100)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="text-center mt-7 pb-0">
        <v-text-field
            v-model.trim="qtdDias"
            label="Dias de validade"
            :error-messages="erros"
            background-color="white"
            outlined
            dense
            v-mask="'##'"
        />
      </v-card-text>

      <v-card-actions class="mx-2 pt-0 pb-4 mt-3">
        <v-btn text color="orange" @click="aberto = false" depressed>
          <span>Cancelar</span>
        </v-btn>

        <v-spacer/>

        <v-btn text color="green lighten-1" @click="enviar()" depressed>
          <span>Enviar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import clientesServices from "../../services/clientes";
import {mapActions} from "vuex";

export default {
  name: "DialogEnviarContrato",
  mixins: [mixinGeral],
  components: {},
  data() {
    return {
      aberto: false,
      qtdDias: null,
      erros: null,
      idCliente: null,
    };
  },
  computed: {
    habilitacaoSubmit() {
      return !this.loading && !this.notificacao.status;
    },
  },
  methods: {
    ...mapActions({obterInformacoes: "actionObterInformacoes"}),
    exibir() {
      this.aberto = true;
    },
    fechar() {
      this.aberto = false;
    },
    async enviar() {
      var self = this;
      this.ativarLoading();
      await clientesServices.enviarContrato(this.idCliente, this.qtdDias).then(res => {
        this.obterInformacoes();
        this.notificacaoSucesso("Contrato enviado");
        this.desativarLoading();
        this.fechar();
        this.$emit("enviouSucesso");
      }).catch(err => {
        this.setNotificacao({
          status: true,
          mensagem: err.response.data.mensagem,
          categoria: 503,
        });
        this.desativarLoading();

      });
    },
  },
  watch: {},
  created() {
  },
};
</script>

<style scoped>
.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>