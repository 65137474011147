<template>
  <v-dialog v-model="aberto" width="900" persistent :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Renovação de Contrato do Cliente:
            <span class="font-weight-medium orange--text">{{ dados.nome || "" }} </span>
          </div>
          <v-btn icon dark @click="fechar()" color="var(--cor-primaria-100)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <v-expansion-panels focusable v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>Dados do Cliente</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field
                      :value="dados.nome"
                      label="Nome do Cliente"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.email"
                      label="Email do Cliente"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.cpf"
                      label="CPF do Cliente"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.rg"
                      label="RG do Cliente"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.dt_nascimento"
                      label="Data de Nascimento"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.ddd"
                      label="DDD"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.telefone"
                      label="Telefone"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Endereço do Locatário</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-2">
                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.cep"
                      label="CEP do Locatário"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.numero_residencia"
                      label="Número do Locatário"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.logradouro"
                      label="Logradouro do Locatário"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.bairro"
                      label="Bairro do Locatário"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.cidade"
                      label="Cidade do Locatário"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.uf"
                      label="UF do Locatário"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Endereço da Locação</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-2">
                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.cep_locacao"
                      label="CEP do Imóvel"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.numero_residencia_locacao"
                      label="Número do Imóvel"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.logradouro_locacao"
                      label="Logradouro do Imóvel"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.bairro_locacao"
                      label="Bairro do Imóvel"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.cidade_locacao"
                      label="Cidade do Imóvel"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      :value="dados.uf_locacao"
                      label="UF do Imóvel"
                      outlined
                      hide-details
                      dense
                      readonly
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Dados/Valores do Contrato</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="mt-2">
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model.lazy="form.valor_aluguel"
                      label="Valor do Aluguel"
                      outlined
                      dense
                      hide-details
                      prefix="R$"
                      type="number"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model.trim="form.valor_condominio"
                      label="Valor do Condomínio"
                      outlined
                      dense
                      prefix="R$"
                      hide-details
                      type="number"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="form.valor_agua "
                      label="Valor do Conta de Água"
                      outlined
                      dense
                      prefix="R$"
                      hide-details
                      type="number"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="form.valor_luz"
                      label="Valor do Conta de Luz"
                      outlined
                      hide-details
                      dense
                      prefix="R$"
                      type="number"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="form.valor_iptu"
                      label="Valor do IPTU (mensal)"
                      outlined
                      hide-details
                      dense
                      prefix="R$"
                      type="number"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="form.taxa_setup"
                      label="Taxa Setup"
                      outlined
                      hide-details
                      dense
                      prefix="R$"
                      type="number"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="dados.pacote.nome "
                      label="Pacote"
                      outlined
                      hide-details
                      dense
                      readonly
                      background-color="grey lighten-3"
                      color="var(--cor-primaria-100)"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="dados.pacote.porcentagem"
                      label="Porcentagem do Pacote"
                      outlined
                      hide-details
                      dense
                      prefix="%"
                      readonly
                      background-color="grey lighten-3"
                      color="var(--cor-primaria-100)"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="valorTotal"
                      label="Total Anual das Despesas"
                      outlined
                      dense
                      prefix="R$"
                      readonly
                      background-color="grey lighten-3"
                      color="var(--cor-primaria-100)"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="valorTotalComPacote"
                      label="Total a Pagar"
                      outlined
                      dense
                      prefix="R$"
                      readonly
                      background-color="grey lighten-3"
                      color="var(--cor-primaria-100)"
                  />
                </v-col>
                <v-col cols="12" align="center">
                  <v-btn dark color="orange" @click="renovarContrato">
                    Renovar Contrato
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="orange" @click="fechar"> Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import service from "@/services/clientes";
import helpers from "@/assets/js/helpers";
import mixinGeral from "@/mixin/geral";

export default {
  name: "DialogRenovarContrato",
  mixins: [mixinGeral],
  props: {
    cliente_id: Number,
    aberto: Boolean
  },
  data() {
    return {
      panel: 3,
      dados: {
        id: null,
        nome: null,
        email: null,
        cpf: null,
        rg: null,
        dt_nascimento: null,
        ddd: null,
        telefone: null,
        cep: null,
        numero_residencia: null,
        logradouro: null,
        bairro: null,
        cidade: null,
        uf: null,
        cadastro_aprovado: null,
        criado_em: null,
        atualizado_em: null,
        empresa_id: null,
        pacote_id: null,
        cep_locacao: null,
        numero_residencia_locacao: null,
        logradouro_locacao: null,
        bairro_locacao: null,
        cidade_locacao: null,
        uf_locacao: null,
        vendas: {
          valor_aluguel: null,
          valor_condominio: null,
          valor_agua: null,
          valor_luz: null,
          valor_iptu: null,
          taxa_setup: null,
        },
        pacote: {
          id: null,
          nome: null,
          tipo_pagamento: null,
          porcentagem: null,
          quantidade_parcelas: null
        }
      },
      form: {
        cliente_id: null,
        valor_aluguel: null,
        valor_condominio: null,
        valor_agua: null,
        valor_luz: null,
        valor_iptu: null,
        taxa_setup: null,
        pacote_id: null
      },
    };
  },
  watch: {
    cliente_id(val) {
      if (val != null || val != undefined) {
        this.obterDados(val);
      }
    },
  },
  computed: {
    valorTotal() {
      if (typeof this.form.id == null) {
        return 0;
      }
      const aluguel = Number(this.form.valor_aluguel);
      const condominio = Number(this.form.valor_condominio);
      const agua = Number(this.form.valor_agua);
      const luz = Number(this.form.valor_luz);
      const iptu = Number(this.form.valor_iptu);
      const setup = Number(this.form.taxa_setup);

      const totalAluguel = aluguel * 12;
      const totalCondominio = condominio * 12;
      const totalAgua = agua * 12;
      const totalLuz = luz * 12;
      const totalIptu = iptu * 12;

      var resultado = totalAluguel + totalCondominio + totalAgua + totalLuz + totalIptu + setup;

      return resultado.toLocaleString('pt-br', {minimumFractionDigits: 2})
    },
    valorTotalComPacote() {
      const valorTotal = helpers.monetarioBrasilParaNumerico(this.valorTotal);
      if (!this.form.pacote_id || valorTotal === 0) {
        return 0;
      }

      const porcentagem = Number(this.dados.pacote.porcentagem);
      const taxaSetup = helpers.monetarioBrasilParaNumerico(this.form.taxa_setup);
      const totalSemTaxa = valorTotal - taxaSetup;
      const resultadoSemTaxa = (totalSemTaxa * porcentagem) / 100;
      const final = resultadoSemTaxa + taxaSetup;

      return helpers.numericoParaMonetarioBrasil(final.toFixed(2));
    },
  },
  methods: {
    fechar() {
      this.$emit('fechar')
    },
    async obterDados() {
      await service.buscar(this.cliente_id).then(resp => {

        this.dados = resp.data
        const vendas = resp.data.vendas.slice(-1).pop()
        this.dados.pacote = vendas.pacote

        this.form.venda_id = vendas.id;
        this.form.valor_aluguel = vendas.valor_aluguel;
        this.form.valor_condominio = vendas.valor_condominio;
        this.form.valor_agua = vendas.valor_agua;
        this.form.valor_luz = vendas.valor_luz;
        this.form.valor_iptu = vendas.valor_iptu;
        this.form.taxa_setup = vendas.taxa_setup;
        this.form.pacote_id = vendas.pacote_id;
        this.form.cliente_id = vendas.cliente_id;
        this.form.contrato_id = vendas.contrato_id;

        this.form.venda_id = this.form.id;
        this.form.cliente_id = resp.data.id;
      });
    },
    async renovarContrato() {
      await service.renovarContrato(this.cliente_id, this.form).then(resp => {
        this.setNotificacao({
          status: true,
          mensagem: resp.data.mensagem,
          categoria: 200
        });
        this.fechar()
      });
    }
  },
};
</script>

