<template>
  <v-dialog v-model="aberto" max-width="700" persistent :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Detalhes do Cliente</div>
          <v-btn icon dark @click="fechar()" color="var(--cor-primaria-100)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <v-row justify="center" class="mb-5" v-if="pendencias == 0">
          <h3>Deseja realmente inativar o cliente?</h3>
        </v-row>
        <div v-else>
          <h3 align="center">Deseja realmente inativar o cliente com <strong class="text--black">Pendencias?</strong>
          </h3>
          <br>
          <div v-for="dado in dados" v-if="dado.status_id === 1">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="6" md="3" sm="3">
                    <span><strong>Referencia:</strong><br> {{ dado.referencia | formataMes }}</span>
                  </v-col>
                  <v-col cols="6" md="3" sm="3">
                    <span><strong>Vencimento:</strong><br> {{ dado.data_vencimento |formataData }}</span>
                  </v-col>
                  <v-col cols="6" md="3" sm="3">
                    <span><strong>Valor:</strong><br> R$ {{ dado.valor | money }}</span>
                  </v-col>
                  <v-col cols="6" md="3" sm="3">
                    <span><strong>Status:</strong><br> {{ dado.status }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <br>
          </div>
        </div>

        <v-row justify="center">
          <v-btn
              dark
              small
              color="orange darken-4"
              @click="inativarCliente">
            Inativar Cliente
          </v-btn>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="orange"
            @click="fechar">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import service from "@/services/inadimplencia";
import clientes_service from "@/services/clientes";

export default {
  name: "DialogInativar",
  mixins: [mixinGeral],
  components: {},
  props: {
    cliente_id: Number,
    aberto: Boolean
  },
  data() {
    return {
      dados: null,
      pendencias: 0
    };
  },
  computed: {},
  watch: {
    cliente_id(val) {
      if (val != null || val != undefined) {
        this.verificarInadimplenciasPendentes(val);
      }
    },
  },
  created() {
  },
  methods: {
    async verificarInadimplenciasPendentes() {
      await service.verificarInadimplenciasPendentes(this.cliente_id).then(resp => {
        this.dados = resp.data
        resp.data.forEach(el => {
          this.pendencias += (el.status_id == 1) ? 1 : 0;
        })
      });
    },
    async inativarCliente() {

      await clientes_service.inativarCliente(this.cliente_id).then(resp => {
        this.setNotificacao({
          status: true,
          mensagem: resp.data.mensagem,
          categoria: 200
        });
        this.fechar()
      }).catch(error => {
        this.setNotificacao({
          status: true,
          mensagem: error.response.data.erros,
          categoria: 400
        });
      });

    },
    fechar() {
      this.$emit('fechar');
      this.pendencias = 0;
    },
  },
};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>