<template>
  <v-autocomplete
      label="Status de pagamento"
      placeholder="Selecione o status"
      :items="options_status"
      item-text="text"
      item-value="text"
      outlined
      dense
      v-model="status_selected"
      hide-details
      clearable
      prepend-inner-icon="mdi-cash-multiple"
      v-on:change="(event) => this.$emit('statusSelecionado', status_selected)"
  />
</template>

<script>
export default {
  name: "AutocompleteStatusVenda",
  props: {
    id: [Number, String]
  },
  data: () => ({
    status_selected: null,
    options_status: [
      {id: 1, text: "Pendente"},
      {id: 2, text: "Pago"},
      {id: 3, text: "Processando"},
      {id: 4, text: "Cancelado"},
      {id: 5, text: "Recusado"}
    ],
  }),
  async created() {
    if (this.id) {
      this.status_selected = this.id
    }
  },
  watch: {
    id: function (val) {
      this.status_selected = val;
    }
  },
}
</script>
