<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>Clientes Cadastrados</titulo-pagina>
        </v-col>
        <v-col cols="12">
          <v-card class="rounded-lg">
            <v-card-text>
              <v-row>
                <v-col class="d-block">
                  <div class="d-flex justify-space-between">
                    <div class="flex d-inline-flex align-center flex-wrap">
                      <v-btn color="white" class="mr-3 mb-2" small @click="obterInformacoes">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                      <v-btn color="white" class="mr-3 mb-2" small @click="modalFiltro = true">
                        <v-icon>mdi-filter</v-icon>
                      </v-btn>
                      <v-chip
                          v-for="(value, key) in filtersChip" :key="key"
                          v-if="(value != null && value.length > 0)"
                          class="mr-3 mb-2"
                          close
                          color="orange"
                          label
                          outlined
                          @click:close="filtros[key] = null"
                      > {{ key | primeiraLetraMaiuscula }}: {{ value | formataData }}
                      </v-chip>
                    </div>
                    <v-btn class="ml-3 float-end" color="orange" to="/clientes/cadastrar" dark small>
                      <v-icon>mdi-plus</v-icon>
                      Novo Cliente
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                      :headers="headersTabela"
                      :items="dadosTabela"
                      locale="pt"
                  >
                    <template v-slot:item.criadoEm="{ item }">
                      {{ item.criadoEm | formataData }}
                    </template>
                    <template v-slot:item.cadastroAprovado="{ item }">
                      <span
                          :class="[obterClasseCadastro(item), permissaoMaster && 'cursor-pointer']"
                          @click="verLogSerasa(item)"
                      >
                        {{ item.cadastroAprovado ? "Aprovado" : "Reprovado" }}
                      </span>
                    </template>
                    <template v-slot:item.contrato.clicksignStatus="{ item }">
                      <span :class="obterClasseContrato(item)">
                        {{ traduzirClicksign(item.contrato.clicksignStatus) }}
                      </span>
                    </template>
                    <template v-slot:item.venda_atual.status.descricao="{ item }">
                      <span :class="setColorStatusVenda(item.venda_atual.status_id)">
                        {{ item.venda_atual.status.descricao }}
                      </span>
                    </template>
                    <template v-slot:item.statusId="{ item }">
                      <span :class="obterClasseStatus(item.statusId)">
                        {{ traduzirStatusCliente(item.statusId) }}
                      </span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn icon @click="abrirDetalhes(item)">
                        <v-icon color="grey darken-1" medium>mdi-arrow-expand-all</v-icon>
                      </v-btn>
                      <v-btn icon @click="abrirAcoes(item)">
                        <v-icon color="primary" medium>mdi-vector-arrange-below</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


      <dialog-filtro
          ref="DialogFiltro"
          :aberto="modalFiltro"
          @fechar="modalFiltro = false"
          @filtrar="filtrar"
      />
      <dialog-reanalises
          ref="DialogReanalises"
          :cliente_id="modalReanalises.cliente_id"
          :aberto="modalReanalises.aberto"
          @fechar="modalReanalises.cliente_id = null, modalReanalises.aberto = false"
      />
      <dialog-renovar-contrato
          ref="DialogReanalises"
          :cliente_id="modalRenovarContrato.cliente_id"
          :aberto="modalRenovarContrato.aberto"
          @fechar="modalRenovarContrato.cliente_id = null, modalRenovarContrato.aberto = false"
      />
      <dialog-formulario-inadimplencia
          ref="DialogFormularioInadimplencia"
          :cliente_id="modalFormInadimplencia.cliente_id"
          :aberto="modalFormInadimplencia.aberto"
          @fechar="modalFormInadimplencia.cliente_id = null, modalFormInadimplencia.aberto = false"
      />
      <dialog-inativar
          ref="DialogInativar"
          :cliente_id="modalInativar.cliente_id"
          :aberto="modalInativar.aberto"
          @fechar="modalInativar.cliente_id = null, modalInativar.aberto = false, obterInformacoes()"
      ></dialog-inativar>
      <dialog-detalhes ref="dialogDetalhes"/>
      <dialog-consulta-serasa ref="dialogConsultaSerasa"/>
      <dialog-enviar-contrato ref="dialogEnviarContrato"/>
      <v-dialog v-model="modalAcoes.status" width="700" persistent @keydown.esc="fecharAcoes()">
        <v-card>
          <v-card-title class="headline"></v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-card elevation="2" class="text-center" @click="abrirDetalhes(modalAcoes.item)">
                  <v-icon class="mt-5" color="grey darken-1">
                    mdi-arrow-expand-all mdi-48px
                  </v-icon>
                  <v-card-subtitle> Detalhes do Cadastro</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" class="text-center" @click="reconsultarSerasa(modalAcoes.item)"
                        :disabled="bloquearReconsultaSerasa(modalAcoes.item)">
                  <v-icon class="mt-5" color="green darken-1" :disabled="bloquearReconsultaSerasa(modalAcoes.item)">
                    mdi-cached mdi-48px
                  </v-icon>
                  <v-card-subtitle> Reconsultar Serasa</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" class="text-center" @click="abrirFormEnviarContrato(modalAcoes.item)"
                >
                  <v-icon class="mt-5" color="orange darken-2">
                    mdi-file-document mdi-48px
                  </v-icon>
                  <v-card-subtitle> Enviar Contrato</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" class="text-center" @click="obterUrlContrato(modalAcoes.item)"
                        :disabled="bloquearDownloadContrato(modalAcoes.item)">
                  <v-icon class="mt-5" color="teal lighten-1" :disabled="bloquearDownloadContrato(modalAcoes.item)">
                    mdi-download mdi-48px
                  </v-icon>
                  <v-card-subtitle> Download Contrato</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" class="text-center" @click="cancelarContrato(modalAcoes.item)"
                        :disabled="bloquearCancelamentoContrato(modalAcoes.item)">
                  <v-icon class="mt-5" color="blue-grey" :disabled="bloquearCancelamentoContrato(modalAcoes.item)">
                    mdi-close-outline mdi-48px
                  </v-icon>
                  <v-card-subtitle> Cancelar Contrato</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" class="text-center" @click="irParaEdicao(modalAcoes.item)"
                        :disabled="bloquearEdicao(modalAcoes.item)">
                  <v-icon class="mt-5" color="blue darken-1" :disabled="bloquearEdicao(modalAcoes.item)">
                    mdi-pencil mdi-48px
                  </v-icon>
                  <v-card-subtitle> Editar Cadastro</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" class="text-center" :disabled="!bloquearInativar(modalAcoes.item)"
                        @click="modalInativar.aberto = true, modalInativar.cliente_id = modalAcoes.item.id">
                  <v-icon class="mt-5" color="red darken-4" :disabled="!bloquearInativar(modalAcoes.item)">
                    mdi-account-off mdi-48px
                  </v-icon>
                  <v-card-subtitle> Inativar Cliente</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" align="center" @click="abrirSolicitarReanalises(modalAcoes.item)"
                        :disabled="bloquearReanalise(modalAcoes.item)">
                  <v-icon class="mt-5" color="deep-purple darken-1" :disabled="bloquearReanalise(modalAcoes.item)">
                    mdi-48px mdi-file-check-outline
                  </v-icon>
                  <v-card-subtitle>Solicitar Reanalise</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" align="center" @click="abrirRenovarContrato(modalAcoes.item)"
                        :disabled="bloquearRenovacao(modalAcoes.item)">
                  <v-icon class="mt-5" color="lime darken-1" :disabled="bloquearRenovacao(modalAcoes.item)">
                    mdi-48px mdi-handshake-outline
                  </v-icon>
                  <v-card-subtitle>Renovar Contrato</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" align="center" @click="abrirFormInadimplencia(modalAcoes.item)">
                  <v-icon class="mt-5" color="black">
                    mdi-48px mdi-cash-remove
                  </v-icon>
                  <v-card-subtitle>Registrar Inadimplência</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" align="center" @click="atuaizarSignatario(modalAcoes.item)"
                        :disabled="bloquearAtualizarSignatario(modalAcoes.item)">
                  <v-icon class="mt-5" color="orange darken-2" :disabled="bloquearAtualizarSignatario(modalAcoes.item)">
                    mdi-48px mdi-file-document-edit
                  </v-icon>
                  <v-card-subtitle>Atualizar Signatário</v-card-subtitle>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="2" align="center" @click="alterarCartaoAssinatura(modalAcoes.item)"
                                          :disabled="bloquearTrocaCartao(modalAcoes.item)">
                  <v-icon class="mt-5" color="blue darken-2" :disabled="bloquearTrocaCartao(modalAcoes.item)">
                    mdi-48px mdi-credit-card-multiple
                  </v-icon>
                  <v-card-subtitle>Atualizar Cartão</v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="orange" @click="fecharAcoes">Fechar</v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import DialogConfirmacao from "../../components/DialogConfirmacao";
import DialogDetalhes from "../../components/clientes/DialogDetalhes";
import DialogConsultaSerasa from "../../components/clientes/DialogConsultaSerasa";
import DialogEnviarContrato from "../../components/contratos/DialogEnviarContrato";
import mixinGeral from "../../mixin/geral";
import clientesServices from "../../services/clientes";
import DialogFiltro from "@/components/clientes/DialogFiltro";
import DialogReanalises from "@/components/reanalise/DialogReanalises";
import {mapActions} from "vuex";
import DialogRenovarContrato from "@/components/clientes/DialogRenovarContrato";
import DialogFormulario from "@/components/inadimplencia/DialogFormulario";
import DialogFormularioInadimplencia from "@/components/inadimplencia/DialogFormulario";
import DialogInativar from "@/components/clientes/DialogInativar";

export default {
  name: "Index",
  mixins: [mixinGeral],
  components: {
    DialogInativar,
    DialogFormularioInadimplencia,
    DialogRenovarContrato,
    MenuPrincipal,
    TituloPagina,
    DialogConfirmacao,
    DialogDetalhes,
    DialogEnviarContrato,
    DialogConsultaSerasa,
    DialogFiltro,
    DialogReanalises,
    DialogFormulario
  },
  data() {
    return {
      idExcluir: null,
      modalFiltro: false,
      modalAcoes: {
        status: false,
        item: null,
      },
      modalReanalises: {
        cliente_id: null,
        aberto: false
      },
      modalFormInadimplencia: {
        cliente_id: null,
        aberto: false
      },
      modalInativar: {
        cliente_id: null,
        aberto: false
      },
      modalRenovarContrato: {
        cliente_id: null,
        aberto: false
      },
      filtros: {
        nome: null,
        cpf: null,
        rg: null,
        cidade: null,
        uf: null,
        empresa: null,
        statusId: null,
        statusVenda: null,
        aprovacaoSerasa: true,
        criadoEm: [],
      },
    };
  },
  computed: {
    headersTabela() {
      return [
        {
          text: "Nome",
          value: "nome",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Data Cadastro",
          value: "criadoEm",
          align: "start",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Aprovação",
          value: "cadastroAprovado",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Status da Assinatura",
          value: "contrato.clicksignStatus",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Status do Pagamento",
          value: "venda_atual.status.descricao",
          align: "start",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Status do Cliente",
          value: "statusId",
          align: "center",
          class: "sem-quebra",
          cellClass: "sem-quebra font-size-13",
        },
        {
          text: "Opções",
          value: "actions",
          sortable: false,
          class: "",
          cellClass: "sem-quebra",
        },
      ];
    },
    dadosTabela() {
      const clientes = this.registrosFiltrados;
      const retorno = [];

      if (clientes.length === 0) {
        return retorno;
      }

      clientes.forEach((c) => {
        let cliente = {...c};
        cliente.contrato = this.buscarContrato(cliente);
        retorno.push(cliente);
      });

      return retorno;
    },
    itensFiltroEmpresa() {
      if (!this.permissaoMaster) return [];
      return this.empresas.map((empresa) => {
        return {
          id: empresa.id,
          valor: empresa.razaoSocial,
        };
      });
    },
    registrosFiltrados() {
      if (!this.clientes) return [];
      let filtrados = [...this.clientes];

      const filtrandoNome = !!this.filtros.nome;
      const filtrandoCpf = !!this.filtros.cpf;
      const filtrandoRg = !!this.filtros.rg;
      const filtrandoCidade = !!this.filtros.cidade;
      const filtrandoUf = !!this.filtros.uf;
      const filtrandoEmpresa = !!this.filtros.empresa;
      const filtrandoAprovacaoSerasa = this.filtros.aprovacaoSerasa !== null;
      const filtrarDataCadastro = !!this.filtros.criadoEm;
      const statusId = !!this.filtros.statusId;
      const filtrandoStatusVenda = !!this.filtros.statusVenda;

      if (filtrandoNome) filtrados = this.filtrarNome(filtrados);
      if (filtrandoCpf) filtrados = this.filtrarCpf(filtrados);
      if (filtrandoRg) filtrados = this.filtrarRg(filtrados);
      if (filtrandoCidade) filtrados = this.filtrarCidade(filtrados);
      if (filtrandoUf) filtrados = this.filtrarUf(filtrados);
      if (filtrandoEmpresa) filtrados = this.filtrarEmpresa(filtrados);
      if (filtrandoAprovacaoSerasa) filtrados = this.filtrarAprovacaoSerasa(filtrados);
      if (filtrarDataCadastro) filtrados = this.filtrarDataCadastro(filtrados);
      if (statusId) filtrados = this.filtrarStatusId(filtrados);
      if (filtrandoStatusVenda) filtrados = this.filtrarStatusVenda(filtrados);

      return filtrados;
    },
    filtersChip() {
      const retorno = {};

      if (this.filtros.nome) retorno.nome = this.filtros.nome;
      if (this.filtros.cpf) retorno.cpf = this.filtros.cpf;
      if (this.filtros.rg) retorno.rg = this.filtros.rg;
      if (this.filtros.cidade) retorno.cidade = this.filtros.cidade;
      if (this.filtros.uf) retorno.uf = this.filtros.uf;

      if (this.filtros.empresa) {
        const empresa = this.empresas.find(e => parseInt(e.id) === parseInt(this.filtros.empresa));
        if (empresa) retorno.empresa = `${empresa.razaoSocial.substring(0, 20)}...`;
        else retorno.empresa = `""`;
      }
      if (this.filtros.aprovacaoSerasa !== null) retorno.aprovacaoSerasa = `${this.filtros.aprovacaoSerasa ? 'Aprovado' : 'Reprovado'}`;
      if (this.filtros.criadoEm) retorno.criadoEm = this.filtros.criadoEm;
      if (this.filtros.statusVenda) retorno.statusVenda = this.filtros.statusVenda;
      return retorno;
    },
  },
  methods: {
    ...mapActions({obterInformacoes: "actionObterInformacoes"}),
    obterClasseCadastro(item) {
      let retorno = "font-weight-bold";

      if (item.cadastroAprovado) return (retorno += " green--text");

      return (retorno += " red--text");
    },
    obterClasseContrato(item) {
      const status = item.contrato.clicksignStatus;
      let retorno = "font-weight-bold";

      if (status === "enviado") return (retorno += " blue--text");
      else if (status === "assinado") return (retorno += " green--text");
      else if (status === "cancelado") return (retorno += " red--text");
      else if (status === "expirado") return (retorno += " red--text");
      else return (retorno += " grey--text text--darken-1");
    },

    setColorStatusVenda(id) {
      var retorno = 'font-weight-bold'
      if (id === 1) return (retorno += " grey--text");
      if (id === 2) return (retorno += " green--text");
      if (id === 3) return (retorno += " blue--text");
      if (id === 4 || id === 5) return (retorno += " red--text");
      return (retorno += " grey--text text--darken-1");
    },

    obterClasseStatus(status) {
      let retorno = "font-weight-bold";

      if (status === 1) return (retorno += " green--text");
      if (status === 2) return (retorno += " red--text");
      return (retorno += " grey--text text--darken-1");
    },
    obterClassePagamento(item) {
      const status = item.contrato.pagarmeStatus;
      let retorno = "font-weight-bold";

      if (["processing", "authorized", "waiting_payment", "active", "aguardando_assinatura_pagarme"].includes(status)) {
        return (retorno += " blue--text");
      }

      if (["paid"].includes(status)) {
        return (retorno += " green--text");
      }

      if (["refunded", "pending_refund", "refused", "chargedback"].includes(status)) {
        return (retorno += " red--text");
      }

      return (retorno += " grey--text text--darken-1");
    },
    abrirDetalhes(item) {
      this.$refs.dialogDetalhes.dados = item;
      this.$refs.dialogDetalhes.exibir();
    },
    abrirSolicitarReanalises(item) {
      this.modalReanalises.cliente_id = item.id;
      this.modalReanalises.aberto = true;
    },
    abrirRenovarContrato(cliente) {
      if (!this.bloquearRenovacao(cliente)) {
        this.modalRenovarContrato.cliente_id = cliente.id;
        this.modalRenovarContrato.aberto = true;
      }
    },
    abrirFormInadimplencia(item) {
      this.modalFormInadimplencia.cliente_id = item.id;
      this.modalFormInadimplencia.aberto = true;
    },
    buscarContrato(cliente) {
      const id = cliente.id;

      let contratos = [...this.contratos];
      let filtrados = contratos
          .filter((contrato) => {
            return contrato.clienteId === id;
          })
          .sort((a, b) => (b.id > a.id ? 1 : -1));

      if (filtrados.length === 0) return {};

      return filtrados[0];
    },
    bloquearEdicao(cliente) {
      return false
      if (cliente == null) {
        return true
      }
      const contrato = this.buscarContrato(cliente);
      const status = !!contrato ? contrato.clicksignStatus : null;
      return status === "enviado" || status === "assinado";
    },
    bloquearExclusao(cliente) {
      if (cliente == null) {
        return true
      }
      const contrato = cliente.contrato;
      const status = !!contrato ? contrato.clicksignStatus : null;
      return status === "enviado";
    },
    bloquearCancelamentoContrato(cliente) {
      if (cliente == null) {
        return true
      }
      const contrato = this.buscarContrato(cliente);
      const status = !!contrato ? contrato.clicksignStatus : null;
      return status !== "enviado";
    },
    bloquearAtualizarSignatario(cliente) {
      if ([null, undefined].includes(cliente?.contrato)) {
        return true;
      }
      return cliente.contrato.clicksignStatus !== "enviado";
    },
    bloquearTrocaCartao(cliente) {
      if(!cliente?.venda_atual?.assinaturaAtiva) {
        return true;
      }

      if(cliente?.venda_atual?.assinaturaAtiva.forma_pagamento !== 'cartao') {
        return true;
      }

      return false;
    },
    bloquearGeracaoContrato(cliente) {
      if (cliente == null) {
        return true
      }
      const contrato = this.buscarContrato(cliente);
      const status = !!contrato ? contrato.clicksignStatus : null;
      return ["enviado", "assinado"].includes(status) || !cliente.cadastroAprovado;
    },
    bloquearReconsultaSerasa(cliente) {
      if (cliente == null) {
        return true
      }
      return cliente.contrato.pagarmeStatus === "paid";
    },
    bloquearReanalise(cliente) {
      if (cliente == null) {
        return true
      }
      return cliente.cadastroAprovado;
    },
    bloquearInativar(cliente) {
      if (cliente == null) {
        return true
      }
      return cliente.statusId == 1
    },
    bloquearRenovacao(cliente) {
      // return cliente?.venda_atual?.status_id !== 2;
      return false;
    },
    bloquearDownloadContrato(cliente) {
      if (cliente == null) {
        return true
      }
      const contrato = this.buscarContrato(cliente);
      return !contrato.clicksignStatus;
    },
    bloquearReenvioContrato(cliente) {
      const contrato = this.buscarContrato(cliente);
      const status = !!contrato ? contrato.clicksignStatus : null;
      return status !== "enviado";
    },
    async reconsultarSerasa(cliente) {
      if (!window.confirm("Confirmar consulta à Serasa?")) {
        return;
      }

      this.ativarLoading();
      try {
        await clientesServices.reconsultarSerasa(cliente.id);
        await this.obterInformacoes();
        this.notificacaoSucesso("Aprovação Atualizada");
      } catch (e) {
        this.setNotificacao({
          status: true,
          mensagem: "Falha ao consultar",
          categoria: 503,
        });
      } finally {
        this.desativarLoading();
      }
    },
    abrirFormEnviarContrato(cliente) {
      // if (this.bloquearGeracaoContrato(cliente)) {
      //   return;
      // }

      this.$refs.dialogEnviarContrato.idCliente = cliente.id;
      this.$refs.dialogEnviarContrato.exibir();
    },
    alterarCartaoAssinatura(cliente) {
      if (cliente?.venda_atual?.assinaturaAtiva.uuid) {
        window.open(process.env.VUE_APP_URLBASE + '/alterar-cartao/'+cliente?.venda_atual?.assinatura[0].uuid, '_blank')
      }
    },
    async atuaizarSignatario(cliente) {
      await this.$confirm("Essa ação vai remover o signatário atual do documento, e adicionar um novo conforme os dados do cadastro do cliente." +
          " <br>Deseja realmente atualizar o Signatário do Documento?").then((res) => {
        if (res) {
          try {
            clientesServices.atualizarSignatario(cliente.id);
            this.notificacaoSucesso("Contrato enviado para o email: " + cliente.email);
          } catch (e) {
            this.setNotificacao({
              status: true,
              mensagem: "Falha ao atualizar Signatario",
              categoria: 503,
            });
          }
        }
      })
    },
    async cancelarContrato(cliente) {
      if (this.bloquearCancelamentoContrato(cliente)) return;
      if (!window.confirm("Confirmar cancelamento do contrato?")) {
        return;
      }
      this.ativarLoading();
      try {
        await clientesServices.cancelarContrato(cliente.id);
        await this.obterInformacoes();
        this.notificacaoSucesso("Contrato cancelado");
      } catch (e) {
        this.setNotificacao({
          status: true,
          mensagem: "Falha ao cancelar",
          categoria: 503,
        });
      } finally {
        this.desativarLoading();
      }
    },
    async reenviarContrato(cliente) {
      if (this.bloquearReenvioContrato(cliente)) return;
      if (!window.confirm("Confirmar reenvio do contrato?")) {
        return;
      }
      this.ativarLoading();
      try {
        await clientesServices.reenviarContrato(cliente.id);
        await this.obterInformacoes();
        this.notificacaoSucesso("Contrato enviado");
      } catch (e) {
        this.setNotificacao({
          status: true,
          mensagem: "Falha ao enviar",
          categoria: 503,
        });
      } finally {
        this.desativarLoading();
      }
    },
    async excluirCliente() {
      this.ativarLoading();
      try {
        await clientesServices.excluir(this.idExcluir);
        await this.obterInformacoes();
        this.notificacaoSucesso("Cliente excluído");
      } catch (e) {
        this.setNotificacao({
          status: true,
          mensagem: "Falha ao excluir",
          categoria: 503,
        });
      } finally {
        this.desativarLoading();
      }
    },
    async obterUrlContrato(cliente) {
      this.ativarLoading();
      try {
        let response = await clientesServices.obterUrlContrato(cliente.id);
        let url = response.data.url;
        const link = document.createElement("a");
        link.href = url;
        link.click();
      } catch (e) {
        this.setNotificacao({
          status: true,
          mensagem: "Falha ao buscar contrato",
          categoria: 503,
        });
      } finally {
        this.desativarLoading();
      }
    },
    irParaEdicao(cliente) {
      if (this.bloquearEdicao(cliente)) return;
      const id = cliente.id;
      this.navegar("clientesEditar", {id});
    },
    confirmarExclusao(cliente) {
      if (this.bloquearExclusao(cliente)) return;
      this.idExcluir = cliente.id;
      this.$refs.dialogConfirmacao.modelo = "excluirCliente";
      this.$refs.dialogConfirmacao.exibir();
    },
    filtrarNome(filtrados) {
      return filtrados.filter((f) => {
        return f.nome.toUpperCase().indexOf(this.filtros.nome.toUpperCase()) !== -1;
      });
    },
    filtrarCpf(filtrados) {
      return filtrados.filter((f) => {
        return f.cpf.toUpperCase().indexOf(this.filtros.cpf.toUpperCase()) !== -1;
      });
    },
    filtrarRg(filtrados) {
      return filtrados.filter((f) => {
        return f.rg.toUpperCase().indexOf(this.filtros.rg.toUpperCase()) !== -1;
      });
    },
    filtrarCidade(filtrados) {
      return filtrados.filter((f) => {
        return f.cidade.toUpperCase().indexOf(this.filtros.cidade.toUpperCase()) !== -1;
      });
    },
    filtrarUf(filtrados) {
      return filtrados.filter((f) => {
        return f.uf.toUpperCase().indexOf(this.filtros.uf.toUpperCase()) !== -1;
      });
    },
    filtrarStatusId(filtrados) {
      return filtrados.filter((f) => {
        return f.statusId === this.filtros.statusId;
      });
    },
    filtrarAprovacaoSerasa(filtrados) {
      return filtrados.filter((f) => {
        return f.cadastroAprovado === this.filtros.aprovacaoSerasa;
      });
    },
    filtrarStatusVenda(filtrados) {
      return filtrados.filter((f) => {
        return f.venda_atual.status.descricao === this.filtros.statusVenda;
      });
    },
    filtrarEmpresa(filtrados) {
      return filtrados.filter((f) => {
        return f.empresaId === Number(this.filtros.empresa);
      });
    },
    filtrarDataCadastro(filtrados) {
      return filtrados.filter((f) => {
        if (!this.filtros.criadoEm) return true;
        let criadoEm = [];
        if (!Array.isArray(this.filtros.criadoEm)) criadoEm.push(this.filtros.criadoEm);
        else criadoEm = this.filtros.criadoEm;
        if (criadoEm.length === 0) return true;
        if (this.filtros.criadoEm.length === 1)
          return f.criadoEm >= criadoEm[0];
        else
          return f.criadoEm >= criadoEm[0] && f.criadoEm <= criadoEm[1];
      });
    },
    verLogSerasa(item) {
      if (!this.permissaoMaster || !item.logSerasa) return;
      this.$refs.dialogConsultaSerasa.dados = item.logSerasa;
      this.$refs.dialogConsultaSerasa.exibir();
    },
    filtrar(obj) {
      this.filtros = obj;
    },
    abrirAcoes(item) {
      this.modalAcoes.status = true;
      this.modalAcoes.item = item;
    },
    fecharAcoes() {
      this.modalAcoes.status = false;
      this.modalAcoes.item = null;
    }
  },
  mounted() {
    this.filtros.aprovacaoSerasa = true;
    this.filtros.statusVenda = 'Pago';
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > thead > tr > th {
  color: var(--cor-primaria-100) !important;
}

.sombra {
  -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
</style>
