<template>
  <div>
    <v-dialog
        v-model="aberto"
        width="900px"
        persistent
        @keydown.esc="fechar">
      <v-card class="card-detalhes">
        <v-card-title class="titulo-card cor-principal">
          <div class="d-flex justify-space-between w-100">
            <div>Reanalises</div>
            <v-btn icon dark @click="fechar" color="orange">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text class="mt-5">
          <v-row v-if="reanalises.length <= 0" justify="center">
            <v-col cols="12" align="center"><span class="mb-10">Esse Cliente ainda não possui uma Reanalise.</span>
            </v-col>
            <v-col cols="12" align="center">
              <v-btn color="orange" dark @click="novaReanalise">Criar Nova Reanalise</v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12" v-for="(item, key) in reanalises" :key="key">
              <v-card @click="abrirReanalise(item.id, item.cliente_id)">
                <v-card-title>
                  <h6>Reanalise Cod: {{ item.id }}</h6>
                  <v-spacer></v-spacer>
                  <v-chip outlined dark :color="setColorChip(item.status_id)" >{{ item.reanalise_status.descricao }}</v-chip>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" md="6">
                      <strong>Arquivos:</strong> {{ item.reanalise_documentos.length || 0 }}
                    </v-col>
                    <v-col cols="6" md="6">
                      <strong>Data da Reanalise:</strong> {{ item.criado_em | formataData }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="6">
                      <strong>Observação Cliente:</strong> {{ item.observacao_cliente | textoResumido }}
                    </v-col>
                    <v-col cols="6" md="4" v-if="permissaoMaster">
                      <strong> Observação Auditor:</strong> {{ item.observacao_auditor | textoResumido }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
              color="orange darken-3"
              text
              @click="fechar">Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-reanalise
        ref="DialogReanalise"
        :reanalise_id=modelReanalise.reanalise_id
        :cliente_id=modelReanalise.cliente_id
        :aberto=modelReanalise.aberto
        @fechar=fecharReanalise>
    </dialog-reanalise>

  </div>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import DialogReanalise from "@/components/reanalise/DialogReanalise";
import reanalise from "@/services/reanalise";

export default {
  name: "DialogReanalises",
  mixins: [mixinGeral],
  props: {
    cliente_id: Number,
    aberto: Boolean
  },
  components: {
    DialogReanalise
  },
  data() {
    return {
      modelReanalise: {
        reanalise_id: null,
        cliente_id: null,
        aberto: false
      },
       reanalises: [],
    };
  },
  watch: {
    cliente_id(val) {
      if (val != null || val != undefined) {
        this.getReanalises(val);
      }
    }
  },
  methods: {
    fechar() {
      this.$emit('fechar')
    },
    async getReanalises(cliente_id) {
      await reanalise.buscarPorCliente(cliente_id).then(resp => {
        this.reanalises = resp.data
      });
    },
    novaReanalise() {
      if(!this.verificaAlgumaRenalisePendente()) {
        this.setNotificacao({
          status: true,
          mensagem: "Não é possível criar uma nova Renalise, quando se tem outra(s) pendente(s)",
          categoria: 503,
        });
      }
      this.abrirReanalise(0)
    },
    verificaAlgumaRenalisePendente() {
      if (this.reanalises == null) return false;
      var bloqueado = true
      this.reanalises.forEach(el => {
        if (el.status == "Pendente") {
          bloqueado = false
        }
      })
      return bloqueado
    },
    abrirReanalise(id) {
      this.modelReanalise.reanalise_id = id;
      this.modelReanalise.cliente_id = this.cliente_id;
      this.modelReanalise.aberto = true;
    },
    fecharReanalise() {
      this.modelReanalise.reanalise_id = null;
      this.modelReanalise.cliente_id = null;
      this.modelReanalise.aberto = false;
      this.fechar();
    }
  },

};
</script>